jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

(function($) {
    // Menu trigger
    $('.menu-icon').on('click', function () {
        $('header').toggleClass('menu-open');
        $(this).toggleClass('menu-open');
        $('.menu-area').toggleClass('menu-open');
        // $('.menu-area').slideToggle(500);
        $('html').toggleClass('modal-open');
    });

    $(document).on('mouseup', function(e)  {
        var container = $(".menu-area, .header-menu-trigger");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('header').removeClass('menu-open');
            $('.menu-icon').removeClass('menu-open');
            $('.menu-area').removeClass('menu-open');
        }
    });

    function submenu_open_mobile(){
        if ($(window).width() < 1100) {
            $('.menu-area .navbar > li.menu-item-has-children > a').on('click', function(e){
                $('.menu-area .navbar > li.menu-item-has-children > a').not(this).closest('li.menu-item-has-children').removeClass('opened');
                $('.menu-area .navbar > li.menu-item-has-children > a').not(this).closest('li.menu-item-has-children').find('.submenu-wrap').slideUp(400);
                $('.menu-area .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children').removeClass('opened');
                $('.menu-area .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children').find('.submenu-wrap').slideUp(400);
                $(this).closest('li.menu-item-has-children').toggleClass('opened');
                $(this).closest('li.menu-item-has-children').find('.submenu-wrap').first().slideToggle(400);
                e.preventDefault();
                e.stopPropagation();
            });
            $('.menu-area .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children > a').on('click', function(e){
                $('.menu-area .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children > a').not(this).closest('li.menu-item-has-children').removeClass('opened');
                $('.menu-area .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children > a').not(this).closest('li.menu-item-has-children').find('.submenu-wrap').slideUp(400);
                $(this).closest('li.menu-item-has-children').toggleClass('opened');
                $(this).closest('li.menu-item-has-children').find('.submenu-wrap').first().slideToggle(400);
                e.preventDefault();
                e.stopPropagation();
            });
        } else {
            


            // $('.menu-area .navbar li ul > li').each(function(){
            //     let ActualMenuImage = $(this).closest('.submenu-wrap').find('.submenu-image img').attr('src');
            //     $(this).find('a').on('mouseover', function(){
            //         let menuImage = $(this).data('image');
            //         if(menuImage){
            //             $(this).closest('.submenu-wrap').find('.submenu-image img').attr('src', menuImage);
            //         } else {
            //             $(this).closest('.submenu-wrap').find('.submenu-image img').attr('src', ActualMenuImage);
            //         }
            //     });
            //     $(this).closest('.submenu-wrap').on('mouseleave', function(){
            //         $(this).find('.submenu-image img').attr('src', ActualMenuImage);
            //     });
            // });


            let menu_timeout;
            $('.menu-area .navbar > li.menu-item-has-children').on('mouseenter', function(e){
                let currentMainMenu = $(this);
                $(this).find('.submenu-wrap').first().addClass('open-submenu');
                
            });
            $('.menu-area .navbar > li.menu-item-has-children').on('mouseleave', function(e){
                let currentMainMenu = $(this);
                setTimeout(function(){
                    currentMainMenu.find('.submenu-wrap').first().removeClass('open-submenu');
                    currentMainMenu.find('.submenu-wrap').first().find('.submenu-wrap').first().removeClass('open-submenu');
                }, 300);
            });

            $('.menu-area .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children').on('mouseenter', function(){
                let currentSubMenu = $(this);
                currentSubMenu.find('.submenu-wrap').first().addClass('open-submenu');
                clearTimeout(menu_timeout);
            });
            $('.menu-area .navbar > li.menu-item-has-children .sub-menu > li.menu-item-has-children').on('mouseleave', function(){
                let currentSubMenu = $(this);
                menu_timeout = setTimeout(function(){
                    currentSubMenu.find('.submenu-wrap').removeClass('open-submenu');
                }, 300);
            });


        }


    } 
    submenu_open_mobile();

    // Gallery popup
    $('.open-popup').magnificPopup({
        // delegate: 'a', // child items selector, by clicking on it popup will open
        type: "image",
        fixedContentPos: true,
        gallery: {
            enabled: true,
            tCounter: '<span><strong>%curr%</strong> / %total%</span>',
        },
        closeOnBgClick: false ,
        image: {
            markup:
                '<div class="mfp-figure">' +
                '<div class="close-wraper">' +
                '<div class="mfp-close"></div>' +
                '<div class="back-btn"><span></span>Back to All</div>' +
                "</div>" +
                '<div class="mfp-img"></div>' +
                '<div class="mfp-bottom-bar">' +
                '<div class="magnific-icon"></div>' +
                '<div class="mfp-title"></div>' +
                // '<a download class="popup-download-image" href="'+fullImageLink+'"></a>' +
                '<div class="button-wraper">' +
                '<div class="mfp-counter"></div>' +
                "<button title='previous' type='button' class='mfp-arrow mfp-arrow-left ' id='prev-btn'></button>" +
                "<button title='next' type='button' class='mfp-arrow mfp-arrow-right ' id='next-btn'></button>" +
                "</div>" +
                "</div>" +
                "</div>", // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            cursor: "mfp-zoom-out-cur", // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

            titleSrc: "title", // Attribute of the target element that contains caption for the slide.
            // Or the function that should return the title. For example:
            // titleSrc: function(item) {
            //   return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
            // }
            titleSrc: function(item) {
        
                var caption = item.el.attr('title');
                
                var fullImageLink = item.el.attr('data-full');
                var popupImageLink = item.el.attr('href');
                
                
                return '<div class="caption-txt">' +caption + '</div> <a class="popup-download-image" href="javascript:void(0);"></a><ul class="icon-options"><li><a download href="'+fullImageLink+'">HQ</a></li><li><a download href="'+popupImageLink+'">LQ</a></li></ul>';
            },

            verticalFit: true, // Fits image in area vertically

            tError: '<a href="%url%">The image</a> could not be loaded.', // Error message
        },
        callbacks: {
            updateStatus: function() {
                $('.popup-download-image').on('click', function(){
                    $(this).addClass('option-opened');
                    $(this).closest('.mfp-title').find('.icon-options').addClass('option-opened');
                });
                $(document).on('mouseup', function(e) {
                    if (!$(e.target).is(".mfp-title .icon-options *")) {
                        $('.mfp-title').find('.popup-download-image').removeClass('option-opened');
                        $('.mfp-title').find('.icon-options').removeClass('option-opened');
                    }
                });
            },
        }
    });
    var magnificPopup = $.magnificPopup.instance;

    $("body").on("click", "#prev-btn", function () {
        magnificPopup.prev();
    });
    $("body").on("click", "#next-btn", function () {
        magnificPopup.next();
    });
    $("body").on("click", ".back-btn", function () {
        magnificPopup.close();
    });

    $.magnificPopup.defaults.callbacks = {
        open: function() {
          $('html').addClass('modal-open');
        },
        close: function() {
          // Wait until overflow:hidden has been removed from the html tag
          setTimeout(function() {
            $('html').removeClass('modal-open');
          }, 100)
        }
      };

    // Video popup
    $(".popup-youtube").magnificPopup({
        type: "iframe",
        fixedContentPos: true,
        closeOnBgClick: false ,
        iframe: {
            markup:
                // '<div class="mfp-iframe-scaler">' +
                // '<div class="mfp-close"></div>' +
                // '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                // "</div>", // HTML markup of popup, `mfp-close` will be replaced by the close button

                '<div class="close-wraper">' +
                '<div class="mfp-close"></div>' +
                '<div class="back-btn"><span></span>Back to All</div>' +
                "</div>" +
                '<div class="mfp-figure">' +
                '<div class="mfp-iframe-scaler">' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>' +
                // '<div class="mfp-bottom-bar">' +
                // '<div class="magnific-icon"></div>' +
                // '<div class="mfp-title"></div>' +
                // '<a download class="popup-download-image" href="'+fullImageLink+'"></a>' +
                // '<div class="button-wraper">' +
                // '<div class="mfp-counter"></div>' +
                // "<button title='previous' type='button' class='mfp-arrow mfp-arrow-left ' id='prev-btn'></button>" +
                // "<button title='next' type='button' class='mfp-arrow mfp-arrow-right ' id='next-btn'></button>" +
                // "</div>" +
                // "</div>" +
                "</div>", // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            patterns: {
                youtube: {
                    index: "youtube.com/", // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                    id: "v=", // String that splits URL in a two parts, second part should be %id%
                    // Or null - full URL will be returned
                    // Or a function that should return %id%, for example:
                    // id: function(url) { return 'parsed id'; }

                    src: "//www.youtube.com/embed/%id%?autoplay=1", // URL that will be set as a source for iframe.
                },
                vimeo: {
                    index: "vimeo.com/",
                    id: "/",
                    src: "//player.vimeo.com/video/%id%?autoplay=1",
                },
                gmaps: {
                    index: "//maps.google.",
                    src: "%id%&output=embed",
                },

                // you may add here more sources
            },

            srcAction: "iframe_src", // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
        },
    });
    
    $(".popup-mp4").magnificPopup({
        type: "inline",
        fixedContentPos: true,
        closeOnBgClick: false ,
        callbacks: {
            open: function() {
                $('html').css('margin-right', 0);
                // Play video on open:
                $(this.content).find('video')[0].play();
            },
            close: function() {
                // Reset video on close:
                $(this.content).find('video')[0].load();
            }
        },
    });

    // Gallery pagination
    if($('.photo-gallery-wrap').length){
        $(".holder-photo-gallery").jPages({
            perPage : 20,
            containerID : "photo-gallery-wrap",
            first       : "first",
            previous    : "previous",
            next        : "next",
            last        : "last",
        });
    }

    // Data sheets pagination
    if($('.data-sheets-wrap').length){
        $(".holder-data-sheets").jPages({
            perPage : 6,
            containerID : "data-sheets-wrap",
            first       : "first",
            previous    : "previous",
            next        : "next",
            last        : "last",
        });
    }

    // Image download option H Res/L Res
    $('.photo-item').each(function(){
        $(this).find('.download-image').on('click', function(){
            $(this).addClass('option-opened');
            $(this).closest('.icons').find('.icon-options').addClass('option-opened');
            $('.photo-item').find('.download-image').not(this).removeClass('option-opened').closest('.icons').find('.icon-options').removeClass('option-opened');
        });
    });
    
    
    $(document).on('mouseup', function(e) {
        if (!$(e.target).is(".icons,.icons *")) {
            $('.photo-item').find('.download-image').removeClass('option-opened');
            $('.photo-item').find('.icon-options').removeClass('option-opened');
        }
    });

    $('.download-zip-btn').on('click', function(){
        $(this).toggleClass('download-open');
        $(this).closest('.gallery-download').find('ul').toggleClass('download-open');
    });
    $(document).on('mouseup', function(e) {
        if (!$(e.target).is(".download-zip-btn")) {
            $('.download-zip-btn').removeClass('download-open');
            $('.gallery-download').find('ul').removeClass('download-open');
        }
    });
    // $('.gallery-download ul').on('click', function(){
    //     $('.download-zip-btn').removeClass('download-open');
    //     $('.gallery-download').find('ul').removeClass('download-open');
    // });
    
    

})(jQuery);